<template>
    <div class="mt-16 mb-10 prose font-body">
        <span class="text-3xl">Популярні категорії</span>
    </div>
    <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2 mt-5 mb-10 font-body"
    >
        <div
            v-for="category in filteredCategories"
            :key="category.id"
            @click="navigateToCategory(category.slug)"
            :class="{
                'col-span-1 h-80 border rounded-xl': true, // For mobile devices
                'sm:col-span-2 sm:h-80 sm:border sm:rounded-xl': [
                    1, 2,
                ].includes(category.id), // For small devices
                'lg:row-span-2 lg:h-[30rem] lg:border lg:rounded-xl': [
                    3, 4, 7, 8,
                ].includes(category.id), // For large devices
                'sm:col-span-1 sm:h-80 sm:border sm:rounded-xl': [
                    5, 6, 9, 10,
                ].includes(category.id), // For small devices
            }"
            class="bg-auto bg-center"
            :style="{
                backgroundImage: 'url(' + imagePath(category.image_path) + ')',
                backgroundSize: 'cover',
            }"
        >
            <span>
                <h1 class="mx-5 text-3xl px-3 pt-2 text-black">
                    {{ category.name }}
                </h1>
            </span>
        </div>
    </div>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";
import { Inertia } from "@inertiajs/inertia";
import { computed } from "vue";

const page = usePage();
const categories = computed(() => page.props?.categories || []);

const filteredCategories = computed(() =>
    categories.value.filter((category) => category.id !== 11)
);

const navigateToCategory = (categorySlug) => {
    Inertia.visit(`/category/${categorySlug}`);
};

const imagePath = (path) => {
    return `/storage/${path}`;
};
</script>

<style lang="scss" scoped></style>
