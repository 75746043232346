<template>
    <div class="font-body mt-28">
        <Slides class="w-full " />
        <AdvantagesHomeComponent />
        <CategoryHome />
        <!-- <Loading v-if="loading"></Loading> -->
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { usePage } from "@inertiajs/vue3";

import Slides from "../Components/Slider.vue";
import AdvantagesHomeComponent from "../Components/AdvantagesHomeComponent.vue";
import CategoryHome from "../Components/CategoryHome.vue";

const showModal = ref(true); // Показываем модальное окно по умолчанию
const { props } = usePage();
const restaurants = props.restaurants;

const selectRestaurant = (restaurant) => {
    localStorage.setItem("selectedRestaurant", JSON.stringify(restaurant));
    localStorage.setItem("restaurantSelected", "true"); // Устанавливаем флаг, что ресторан выбран
    showModal.value = false;
};

onMounted(() => {
    const restaurantSelected = localStorage.getItem("restaurantSelected");
    if (!restaurantSelected) {
        showModal.value = true; // Показываем модальное окно, если флаг не установлен
    } else {
        showModal.value = false; // Скрываем модальное окно, если флаг установлен
    }
});

const isOpen = computed(() => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    // Ресторан открыт с 10:00 до 22:00
    return currentHour >= 10 && currentHour < 22;
});

const loading = ref(true);
</script>

<style lang="scss" scoped></style>
