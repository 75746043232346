<template>
    <div class="p-0 mt-5 rounded-xl">
        <!-- Desktop Swiper -->
        <Swiper
            v-if="isDesktop"
            :modules="[EffectFade, Pagination, Autoplay]"
            effect="fade"
            :spaceBetween="30"
            :centeredSlides="true"
            :autoplay="{
                delay: 4000,
                disableOnInteraction: false,
            }"
            :grabCursor="true"
            :loop="true"
            :pagination="true"
        >
            <SwiperSlide v-for="slide in desktopSlides" :key="slide.id">
                <img
                    class="w-full rounded-xl h-96"
                    :src="imagePath(slide.image_path)"
                    alt=""
                />
            </SwiperSlide>
        </Swiper>

        <!-- Mobile Swiper -->
        <Swiper
            v-if="!isDesktop"
            :modules="[EffectFade, Pagination, Autoplay]"
            effect="fade"
            :spaceBetween="30"
            :centeredSlides="true"
            :autoplay="{
                delay: 4000,
                disableOnInteraction: false,
            }"
            :grabCursor="true"
            :loop="true"
            :pagination="true"
        >
            <SwiperSlide v-for="slide in mobileSlides" :key="slide.id">
                <img
                    class="w-full rounded-xl h-96"
                    :src="imagePath(slide.image_path)"
                    alt=""
                />
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectFade, Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import { usePage } from "@inertiajs/vue3";
import { ref, computed, onMounted, onBeforeUnmount } from "vue";

const page = usePage();
const slides = page.props.slides;

const isDesktop = ref(window.innerWidth >= 1024);

const desktopSlides = computed(() => {
    return slides.filter((slide) => slide.is_mobile === 0);
});

const mobileSlides = computed(() => {
    return slides.filter((slide) => slide.is_mobile === 1);
});

const imagePath = (path) => {
    return `/storage/${path}`;
};

const handleResize = () => {
    isDesktop.value = window.innerWidth >= 1024;
};

onMounted(() => {
    window.addEventListener("resize", handleResize);
});

onBeforeUnmount(() => {
    window.removeEventListener("resize", handleResize);
});
</script>

<style lang="scss" scoped></style>
