<template>
    <div class="font-body">
        <div class="my-10 text-3xl prose">
            <h2>Переваги с Хінкальна Колхеті</h2>
        </div>
        <div class="border rounded-xl h-full mt-5">
            <div
                class="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-5 mb-20 font-body"
            >
                <div class="w-full h-full p-10 font-body hidden sm:block">
                    <Swiper
                        :modules="[EffectFade, Pagination, Autoplay]"
                        effect="fade"
                        :spaceBetween="30"
                        :centeredSlides="true"
                        :autoplay="{
                            delay: 4000,
                            disableOnInteraction: false,
                        }"
                        :grabCursor="true"
                        :loop="true"
                        :pagination="true"
                    >
                        <!-- Отображаем только фото с is_mobile = 1 -->
                        <SwiperSlide
                            v-for="photo in mobilePhotos"
                            :key="photo.id"
                        >
                            <img
                                class="w-auto h-auto rounded-xl"
                                :src="`/storage/${photo.image_path}`"
                                :alt="photo.name"
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div class="p-10 w-full h-full my-auto">
                    <div class="flex flex-col mb-10 opacity-75">
                        <span>Щодня</span>
                        <span class="text-3xl mt-2 mb-4">Графік роботи</span>
                        <span class="text-md text-gray-400"
                            >Графік роботи 10:30-22:30 Працюємо навіть під час
                            виключення світла</span
                        >
                    </div>
                    <div class="flex flex-col mt-10">
                        <span>Свята та інші події з Колхеті</span>
                        <span class="text-3xl mt-2 mb-4"
                            >Урочисті події, дні народження з Колхеті</span
                        >
                        <span class="text-md text-gray-400"
                            >Бенкети від 700 грн та подарунки іменинникам</span
                        >
                    </div>
                    <div class="flex flex-col mt-10">
                        <span>Повний контроль</span>
                        <span class="text-3xl mt-2 mb-4"
                            >Робіть замовлення в будь-якій зручній для вас формі
                        </span>
                        <span class="text-md text-gray-400"
                            >Можливість оформлення замовлення за номером
                            телефону</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectFade, Autoplay, Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { usePage } from "@inertiajs/vue3";

// Получаем данные страницы
const page = usePage();

// Принимаем slides как пропс
const props = defineProps({
    slides: Array,
});

console.log("asdfasdfasdf", page.props);

// Фильтруем фотографии с is_mobile = 1
const mobilePhotos = computed(() => {
    return page.props.slides
        ? page.props.slides.filter((slide) => slide.is_mobile === 1)
        : [];
});

console.log("Фильтрованные слайды:", mobilePhotos.value);
</script>

<style lang="scss" scoped></style>
